import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { CenteredTitle } from './Typography';
import TextLoop from "react-text-loop";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;

  position: relative;
  background: var(--theme-background-gradient);

  h1 {
    color: white;
    font-weight: bold;
    font-family: futura-pt, sans-serif;
    margin: 0 1rem;
  }

  ${(props) =>
    props.indexHero &&
    `
     height: 50vh;
  `};
  @media (min-width: 960px) {
    height: 270px;
    h1 {
      padding-top: 0;
      font-size: 60px;
    }
    ${(props) =>
      props.indexHero &&
      `
     height: 55vh;
  `}
  }
`;

const InterChangeableTitleComponent = (data) => {
  const { title, changeableText, img, className } = data;
  const changeableWords = changeableText.split(', ');

  return (
    <BackgroundImage className={className} fluid={img}>
    <Wrapper>
      <CenteredTitle>
        <h1>
          {title} <br/>
          <TextLoop
            interval={4000}
            adjustingSpeed={500}
            mask
          >
            {changeableWords.map(item => <span>{item}</span>)}
          </TextLoop>
        </h1>
      </CenteredTitle>
    </Wrapper>
    </BackgroundImage>
  );
};

export default InterChangeableTitleComponent;
