import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import HeadHelmet from '../../../components/head';
import ContactDiv from '../../../components/contactUs';
import InterChangeableTitleComponent from '../../../components/ui-elements/interChangeableTitle';
import Layout from '../../../components/layout';
import { GetQuoteDiv } from '../../../components/pages/solutions/getQuoteDiv';
import styled from 'styled-components';

import { Inner, CardImg, CardWrapper } from '../../../components/pages/solutions/card';

const AdvantageDiv = styled.div`
  @media (min-width: 0) {
    width: 100%;
    ul {
      columns: 1;
    }
  }
  @media (min-width: 960px) {
    width: 50%;

    ul {
      columns: 2;
    }
  }
  margin: 3rem auto;
  padding: 1rem 0 3rem 0;
  text-align: center;
  ul {
    display: inline-block;
    column-gap: 100px;
    li {
      list-style: none;
      background: url('../../../images/checkMark@3x.png') no-repeat left center;
      background-size: 30px;
      font-family: Lato, sans-serif;
      font-size: 20px;
      text-align: left;
      padding: 10px 0 0 50px;
      margin: 10px 0;
      color: #000000;
    }
  }
`;

const QuotationDiv = styled.div`
  text-align: left;
  width: 80%;
  margin: 1rem auto;
  h3 {
    font-style: italic;
    font-weight: normal !important;
    font-size: 28px;
    color: var(--base-color);
  }
  p {
    font-family: Lato, sans-serif;
    font-size: 20px;
    color: #60616f;
  }
  @media (min-width: 400px) {
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 22px;
    }
  }
  @media (min-width: 500px) {
    margin: 3rem auto;
    h3 {
      font-size: 34px;
    }
    p {
      font-size: 24px;
    }
  }
`;

const SolutionsByRole = ({ data, location }) => {
  const { strapiSsSolutionsByRole } = data;
  const {
    title,
    changableTitle,
    description,
    third_section_content,
    fourth_section_content,
    SolutionRoleCards,
    hero_image_solutions_role,
  } = strapiSsSolutionsByRole;

  return (
    <>
      <HeadHelmet data={strapiSsSolutionsByRole.MetaData} />
      <Layout>
        <InterChangeableTitleComponent
          title={title}
          changeableText={changableTitle}
          img={hero_image_solutions_role.childImageSharp.fluid}
        />
        <GetQuoteDiv>
          <ReactMarkdown>{description}</ReactMarkdown>
        </GetQuoteDiv>
        <Inner>
          {SolutionRoleCards.map((item) => {
            return (
              <CardWrapper>
                <Link to={'/'}>
                  <CardImg>
                    <Img
                      fluid={item.image.childImageSharp.fluid}
                      alt={item.alternativeText || `${title} - image`}
                    />
                  </CardImg>
                </Link>
                <ReactMarkdown className={'inner-content'}>{item.description}</ReactMarkdown>
                <div className="link-wrapper">
                  <p>
                    <a
                      href={item.PDF_file?.publicURL || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LEARN MORE
                    </a>
                  </p>
                </div>
              </CardWrapper>
            );
          })}
        </Inner>
        <div style={{ backgroundColor: '#F7F9FA' }}>
          <AdvantageDiv>
            <ReactMarkdown>{third_section_content}</ReactMarkdown>
          </AdvantageDiv>
        </div>
        <QuotationDiv>
          <ReactMarkdown>{fourth_section_content}</ReactMarkdown>
        </QuotationDiv>
        <ContactDiv />
      </Layout>
    </>
  );
};

export default SolutionsByRole;

export const pageQuery = graphql`
  query {
    strapiSsSolutionsByRole {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      changableTitle
      description
      third_section_content
      fourth_section_content
      hero_image_solutions_role {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SolutionRoleCards {
        id
        description
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        PDF_file {
          publicURL
        }
      }
    }
  }
`;
